@import "variables";
@import '~bootstrap/dist/css/bootstrap.min.css';
@import "custom";
@import "ie-fix";

.column {
  min-width: 100px;
  line-height: 24px;
  cursor: pointer;
  p {
    white-space: nowrap;
  }
  span {
    font-size: 24px;
  }
}